@import url("./assets/fonts/style.css");

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #ecf0f5;
}
:root {
  --font-family-main: "DinText";
  font-family: var(--font-family-main), Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  /* color-scheme: light dark; */
  color: rgba(255, 255, 255, 0.87);
  /* background-color: #242424; */

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  font-weight: bolder;
}
#root {
  /* max-width: 720px; */
  width: "100% !important";
  height: "100% !important";
  padding: "0 !important";
}
* {
  font-family: var(--font-family-main);
}
body {
  width: "100%";
  height: "100%";
  background-color: #242424;
  padding: 0;
  margin: 0;
  display: flex;
  place-items: center;
  min-height: 100vh;
  margin: 0 auto;
}
