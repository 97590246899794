@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 300;
  src: url("./DINNEXTLTARABIC-LIGHT-2-2.ttf") format("truetype-variations");
}

@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 400;
  src: url("./DINNextLTArabic-Regular-4.ttf") format("truetype-variations");
}

@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 500;
  src: url("./DINNextLTArabic-Medium-4.ttf") format("truetype-variations");
}

@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 600;
  src: url("./DINNextLTArabic-Bold-4.ttf") format("truetype-variations");
}

@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 700;
  src: url("./DINNextLTArabic-Heavy2-2.ttf") format("truetype-variations");
}
@font-face {
  font-family: "DinText";
  font-style: normal;
  font-weight: 900;
  src: url("./DINNextLTArabic-Black-4.ttf") format("truetype-variations");
}
